import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  const { spacing, palette } = theme;
  return {
    actions_container: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    directories_actions_container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      padding: `${spacing(1)}px 0px`,
      gap: spacing(1),
    },
    real_estates_actions_container: {
      width: "100%",
      display: "flex",
      paddingBlock: `${spacing(1)}px`,
      justifyContent: "flex-start !important",
    },
    link: {
      border: `1px solid ${palette.grayVariation.grayForBorders} !important`,
      padding: `${spacing(0.5)}px ${spacing(1)}px !important`,
      textTransform: "uppercase",
      "& span": {
        fontWeight: 700,
      },
      "&:hover": {
        backgroundColor: palette.grayVariation.grayHoverButton,
      },
    },
    padding_16: {
      padding: spacing(2),
    },
    no_top_padding: {
      paddingTop: 0,
    },
    button: {
      borderRadius: "0px !important",
      padding: `${spacing(1)}px ${spacing(1.5)} !important`,
      backgroundColor: `${palette.themeColor.primary} !important`,
      textTransform: "none !important",
    },
    button_label: {
      "& .MuiButton-label": {
        fontSize: "12px",
        fontFamily: "'Montserrat', sans-serif",
        color: palette.miscellaneous.white,
        fontWeight: 500,
      },
    },
    directories_label_container: {
      width: "100%",
      padding: `0px ${spacing(1)}px`,
    },
    directories_button_container: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      borderTop: `1px solid ${palette.light.grey}`,
      paddingTop: `${spacing(1)}px`,
      paddingRight: `${spacing(2)}px`,
    },
  };
});

export { useStyles };
